import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { createOverlayLayer } from '../../helpers/layers/createOverlayLayer';

export const PostcodeVisualisation = ({ extentUnitedKingdom }) => {
  const map = useSelector(state => state.maps.map);

  useEffect(() => {
    if (!map)
      return null;

    createOverlayLayer(map, 'Postcode Points', {
      'LAYERS': 'geoptimise:codepoint',
    }, extentUnitedKingdom);
  }, [map, extentUnitedKingdom]);

  return null;
};
